import React, { Component } from "react";
import '../../stylesheets/slices/blog-posts.scss';
import { Link } from 'gatsby';
import { GrayCircle, GrayTriangle } from '../layout/svg';

const getShape = (index) => {
    if (index % 2 === 0) {
        return <GrayCircle />
    } else {
        return <GrayTriangle />
    }
}

class BlogPostGrid extends Component {

    render() {

        const { allPosts, buttonText } = this.props;

        return (
            <div className="slice blog-posts">
                <div className="container">
                    {allPosts.map((post, index) => {
                        return (
                            <div key={index}>
                                <div className="blog-shape">{getShape(index)}</div>
                                <div className="blog-post d-flex flex-wrap" key={index}>
                                    <div className="blog-column image-col d-flex justify-content-start align-items-start">
                                        {post.data.featured_image_excerpt.url ? <img className="w-100" src={post.data.featured_image_excerpt.url} alt={post.data.featured_image_excerpt.alt} /> : null}</div>
                                    <div className="blog-column text-col d-flex flex-wrap h-100 align-items-center">
                                        <div className="d-flex flex-wrap align-items-center">
                                            {post.data.blog_post_title.text ? <h3>{post.data.blog_post_title.text}</h3> : null}
                                            {post.data.excerpt.text ? <h5 className="mt-4">{post.data.excerpt.text}</h5> : null}
                                            <div className="w-100 mt-5"><Link target="_blank" to={`/blog/${post.uid}/`} className="button-outline">{buttonText}</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

}

export default BlogPostGrid;

import React, { Component } from 'react';
import Layout from '../components/layout/layout';
import BlogPostGrid from '../components/slices/BlogPostGrid';
import { graphql } from 'gatsby';
import '../stylesheets/pages/blog.scss';
import Logo from '../components/layout/Logo';

class BlogPage extends Component {

  constructor(props) {

    super(props);

    this.state = {
      windowWidth: '1200',
    };

    this.resizeWindow = this.resizeWindow.bind(this);
  }

  componentDidMount() {

    if (typeof window !== `undefined`) {
      this.resizeWindow();
      window.addEventListener('resize', this.resizeWindow);
    }
  }

  resizeWindow() {

    if (typeof window !== `undefined`) {

      this.setState({
        windowWidth: window.innerWidth,
      });
    }
  }

  render() {

    const data = this.props.data.blogpage.edges[0].node.data;
    const allPosts = this.props.data.posts.nodes;
    const header = this.props.data.header.data;
    const footer = this.props.data.footer.data;

    let backgroundColorLeft = "#fff";
    let backgroundColorRight = "#121112"
    let fontColor = "#121112";

    if (data.header_style === "light") {
      backgroundColorRight = "#fff"
    } else if (data.header_style === "dark") {
      backgroundColorLeft = "#121112";
      fontColor = "#fff";
    }

    let blogHeaderStyle = {
      background: `linear-gradient(to right, ${backgroundColorLeft} 50%, ${backgroundColorRight} 50%)`
    };

    if (this.state.windowWidth >= 768 && this.state.windowWidth <= 992) {
      blogHeaderStyle = {
        background: `linear-gradient(to right, ${backgroundColorLeft} 57%, ${backgroundColorRight} 43%)`
      };
    }

    return (
      this.state.windowWidth > 767 ?
        <Layout headerStyle={data.header_style} title={data.meta_title.text} description={data.meta_description.text} header={header} footer={footer} >
          <div className="blog-page">
            <div className="blog-header" style={blogHeaderStyle}>
              <div className="d-flex flex-wrap">
                <div className="left-column h-100 my-5 d-flex align-items-center flex-wrap">
                  <h2 style={{ color: fontColor }}>{data.intro_heading.text}</h2>
                  <h5 style={{ color: fontColor }} className="mt-5">{data.intro_text.text}</h5>
                </div>
                <div className="right-column d-flex align-items-center justify-content-center">
                  <div className="shapes-container margin-auto">
                    <Logo isAnimating={false} />
                  </div>
                </div>
              </div>
            </div>
            <BlogPostGrid allPosts={allPosts} buttonText={data.button_text.text} />
          </div>
        </Layout> :
        <Layout headerStyle="light" title={data.meta_title.text} description={data.meta_description.text} header={header} footer={footer} >
          <div className="blog-page-mobile">
            <div className="row first-row">
              <div className="left-column">
                <div className="container d-flex flex-wrap ps-4">
                  <h2>{data.intro_heading.text}</h2>
                  <h5 className="mt-5">{data.intro_text.text}</h5>
                </div>
              </div>
              <div className="right-column">
                <div className="container d-flex flex-wrap justify-content-center">
                  <div className="shapes-container margin-auto">
                    <Logo isAnimating={false} />
                  </div>
                </div>
              </div>
            </div>
            <BlogPostGrid allPosts={allPosts} buttonText={data.button_text.text} />
          </div>
        </Layout>
    )
  }

}

export default BlogPage

export const query = graphql`
  {
    blogpage: allPrismicBlogPage {
      edges {
        node {
          uid
          data {
            header_style
            intro_heading {
              text
            }
            intro_text {
              text
            }
            meta_description {
              text
            }
            meta_title {
              text
            }
            page_title {
              text
            }
            button_text {
              text
            }
          }
        }
      }
    },
    posts: allPrismicPost(sort: {fields: data___post_date, order: DESC}) {
      nodes {
        data {
          blog_post_title {
            text
          }
          categories {
            category
          }
          excerpt {
            text
          }
          meta_description {
            text
          }
          meta_title {
            text
          }
          post_date
          featured_image_excerpt {
            alt
            url
          }
        }
        uid
      }
    }

  header: prismicNavigation {
    ...FragmentHeader
  }

  footer: prismicFooter {
    ...FragmentFooter
  }
}
`
